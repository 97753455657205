import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import StylesProvider from '@mui/styles/StylesProvider';
import withStyles from '@mui/styles/withStyles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LicenseInfo } from '@mui/x-license-pro';
import App from 'next/app';
import Head from 'next/head';
import React from 'react';

import defaultTheme from '../lib/default-theme';
import logger from '../lib/logger';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css';
import { TerminalWrapper } from '../lib/TerminalWrapper';

// @ts-ignore
LicenseInfo.setLicenseKey(process.env.MUI_PRO);

// @ts-ignore
const isBrowser = process.browser;
export const styles = () => ({
  '@global': {
    html: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      background: defaultTheme?.palette?.background?.default,
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale', // Antialiasing.
    },
    body: {
      margin: 0,
      padding: 0,
      // width: '100vw',
      height: '100vh',
    },
    '.ql-tooltip': {
      zIndex: 100000,
      top: '-100px !important',
      left: 'unset !important',
    },
    '.auth0-lock-cred-pane-internal-wrapper': { height: 'unset !important' },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
    '.custom-select-menu': {},
    'body ::-webkit-scrollbar': {
      width: '0 !important',
      height: '0 !important',
      background: 'transparent !important',
    },
    'body ::-webkit-scrollbar-thumb': {
      background: '#FF0000 !important',
    },
    ".MuiButtonBase-root [class^='MuiChip-label']": {
      'user-select': 'auto !important;',
    },
    '.MuiChipsInput-TextField .MuiInputLabel-root[data-shrink="false"]': {
      top: '-6px !important',
    },
    '.MuiInputBase-root textarea': {
      minHeight: '-22.75px !important',
    },
    '.MuiInputAdornment-root .MuiButtonBase-root': {
      padding: 0,
      margin: 0,
      marginRight: 2,
    },

    // pdf print
    '@media all': {
      '.page-break': {
        display: 'none',
      },
    },
    '@media print': {
      html: {
        height: 'initial !important',
        overflow: 'initial !important',
        '-webkit-print-color-adjust': 'exact',
      },
      body: {
        height: 'initial !important',
        overflow: 'initial !important',
        '-webkit-print-color-adjust': 'exact',
      },
      '.page-break': {
        pageBreakBefore: 'auto',
        pageBreakAfter: 'auto',
        pageBreakInside: 'avoid',
        margin: '2rem !important',
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    '@page': {
      size: 'auto',
      margin: '20mm',
    },
  },
});
export const generateClassName = createGenerateClassName({ productionPrefix: 'c' });

let AppWrapper = props => props.children;

// @ts-ignore
AppWrapper = withStyles(styles)(AppWrapper);

class MyApp extends App {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
    // this.generateManifest();
  }

  componentDidCatch(error, info) {
    logger.error(error, { info });
  }

  generateManifest = () => {
    if (!isBrowser) return;
    const appName = location?.host?.split('.')?.[0]?.toUpperCase()
      ? `Kentro (${location.host.split('.')[0].toUpperCase()})`
      : 'Kentro';
    const stringManifest = JSON.stringify({
      short_name: appName,
      name: appName,
      description: 'Kentro.io multi-channel orchestration platform',
      display: 'standalone',
      start_url: '/',
      scope: '/',
      background_color: '#FAFAFA',
      theme_color: '#2196f3',
      icons: [
        {
          src: '/favicon-212x150.svg',
          type: 'image/svg+xml',
          sizes: '212x150',
          purpose: 'any',
        },
        {
          src: '/android-chrome-192x192.png',
          sizes: '192x192',
          type: 'image/png',
        },
        {
          src: '/android-chrome-512x512.png',
          sizes: '512x512',
          type: 'image/png',
        },
      ],
      shortcuts: [
        {
          name: 'Customers',
          short_name: 'Customers',
          url: '/customer-support/customers',
          icons: [{ src: '/menu-icon-192.png', sizes: '192x192' }],
        },
        {
          name: 'Sales Orders',
          short_name: 'Orders',
          url: '/customer-support/orders',
          icons: [{ src: '/menu-icon-192.png', sizes: '192x192' }],
        },
        {
          name: 'Shipping Log',
          short_name: 'Shipping Log',
          url: '/fulfillment/shipping-log',
          icons: [{ src: '/menu-icon-192.png', sizes: '192x192' }],
        },
        {
          name: 'Inventory Lookup',
          short_name: 'Lookup',
          url: '/logistics/lookup',
          icons: [{ src: '/menu-icon-192.png', sizes: '192x192' }],
        },
      ],
    });
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    // @ts-ignore
    document.getElementById('manifest-placeholder').setAttribute('href', manifestURL);
  };

  render() {
    const { Component, pageProps } = this.props;

    return (
      <StylesProvider generateClassName={generateClassName}>
        <TerminalWrapper>
          <Head>
            <title key="title">Kentro.io</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
          </Head>
          <AppWrapper>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={defaultTheme}>
                  <CssBaseline />
                  <Component {...pageProps} />
                </ThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </AppWrapper>
        </TerminalWrapper>
      </StylesProvider>
    );
  }
}

export default MyApp;
